*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    width: 100%;
    min-height: 100%;
}

body {
    color: #F5F5FF;
    background-color: #2E1658;
    font-family: 'Ubuntu', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 16px;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img,
picture,
video,
canvas,
svg {
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
    margin: 0;
    box-shadow: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
table,
ul,
ol {
    overflow-wrap: break-word;
    margin-bottom: 1em;
}

p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
blockquote:last-child,
table:last-child,
ul:last-child,
ol:last-child {
    margin-bottom: 0;
}